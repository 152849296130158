import React from 'react';
import { Switch } from 'react-router-dom';

// ****** ****** ****** ↓ 프로그램 ****** ****** ******
// Report -  모두 볼 수 있음
// Write - 학생만 볼 수 있음
// Comment - 선생만 볼 수 있음

// 1. 자기역량관리 [
// 진로설계
import CareerDesignReport from '@pages/Program/IndivAbility/CareerDesign/Report';
import CareerDesignWrite from '@pages/Program/IndivAbility/CareerDesign/Write';
import CareerDesignComment from '@pages/Program/IndivAbility/CareerDesign/Comment';
// 직무기초 포트폴리오
import PortfolioReport from '@pages/Program/IndivAbility/Portfolio/Report';
import PortfolioWrite from '@pages/Program/IndivAbility/Portfolio/Write';
import PortfolioComment from '@pages/Program/IndivAbility/Portfolio/Comment';
// 산업체 채용면접
import VisitIndustryReport from '@pages/Program/IndivAbility/VisitIndustry/Report';
import VisitIndustryWrite from '@pages/Program/IndivAbility/VisitIndustry/Write';
import VisitIndustryComment from '@pages/Program/IndivAbility/VisitIndustry/Comment';
// ]

// 2. 현장실무능력 [
import PracticeAbilityReport from '@pages/Program/PracticeAbility/Report';
import PracticeAbilityWrite from '@pages/Program/PracticeAbility/Write';
import PracticeAbilityComment from '@pages/Program/PracticeAbility/Comment';
// ]

// 3. 전공직무능력1 [
import MajorJobReport from '@pages/Program/MajorJobCert/Report';
import MajorJobComment from '@pages/Program/MajorJobCert/Comment';
// ]

// 4. 전공직무능력2 [
import MajorJobEduReport from '@pages/Program/MajorJobEdu/Report';
import MajorJobEduWrite from '@pages/Program/MajorJobEdu/Write';
import MajorJobEduComment from '@pages/Program/MajorJobEdu/Comment';
// ]

// 5. 직업기초능력[
// 인성 자존감 활동
import TenacityActionReport from '@pages/Program/BasicJob/TenacityAction/Report';
import TenacityActionComment from '@pages/Program/BasicJob/TenacityAction/Comment';
// 직업기초능력평가
import JobAssessReport from '@pages/Program/BasicJob/JobAssess/Report';
import JobAssessWrite from '@pages/Program/BasicJob/JobAssess/Write';
import JobAssessComment from '@pages/Program/BasicJob/JobAssess/Comment';
// ]

// 6. 취업역량강화 [
import EmployAbilityReport from '@pages/Program/EmployAbility/Report';
import EmployAbilityWrite from '@pages/Program/EmployAbility/Write';
import EmployAbilityComment from '@pages/Program/EmployAbility/Comment';
// ]

// 7. 자율교육프로그램 [
import SelfEduProgramReport from '@pages/Program/SelfEduProgram/Report';
import SelfEduProgramWrite from '@pages/Program/SelfEduProgram/Write';
import SelfEduProgramComment from '@pages/Program/SelfEduProgram/Comment';
// ]

// 8. 매칭정보(MatchingInfo) [
// ]

// ****** ****** ****** ↓ 학생 ****** ****** ******
// 기본 [
import SignIn from '@pages/Auth/SignIn';
import Privacy from '@pages/Auth/Privacy';
import Home from '@pages/Home';
import Mypage from '@pages/Student/Mypage';
import MypageEdit from '@pages/Student/Mypage/Edit';
import Notice from '@pages/Student/Service/Notice';
import NoticeDetail from '@pages/Student/Service/NoticeDetail';
import Archive from '@pages/Student/Service/Archive';
import ArchiveDetail from '@pages/Student/Service/ArchiveDetail';

import UseInfo from '@pages/Student/Service/UseInfo';
// ]

// 1. 자기역량관리 [
// 진로설계
// import CareerDesign from '@pages/Student/IndivAbility/CareerDesign';
import IndAbilityNcs from '@pages/Student/IndivAbility/Ncs';
import IndAbilityNcsRegister from '@pages/Student/IndivAbility/Ncs/Register';
// 산업체 채용면접
import VisitIndustry from '@pages/Student/IndivAbility/VisitIndustry';
// ]

// 2. 현장실무능력 [
import PracticeAbility from '@pages/Student/PracticeAbility';
// ]

// 3. 전공직무능력1
import MajorJobCert from '@pages/Student/MajorJobCert';
import MajorJobCertRegister from '@pages/Student/MajorJobCert/Register';

// 4. 전공직무능력2
import MajorJobEdu from '@pages/Student/MajorJobEdu';

// 5. 직업기초능력 [
// 인성 자존감 활동
import TenacityAction from '@pages/Student/BasicJob/TenacityAction';
// 직업기초능력평가
import JobAssess from '@pages/Student/BasicJob/JobAssess';
// ]

// 6. 취업역량강화
import EmployAbility from '@pages/Student/EmployAbility';
// ]

// 7. 자율교육프로그램
import SelfEduProgram from '@pages/Student/SelfEduProgram';

// 8. 매칭정보
import MatchingInfoReport from '@pages/Student/MatchingInfo/Report';

// ****** ****** ****** 선생님 ****** ****** ******
// 기본 [
import TeacherMypage from '@pages/Teacher/Mypage';
import TeacherMypageEdit from '@pages/Teacher/Mypage/Edit';
// ]

// 1. 학생관리(StudentMgmt) [
import StudentMgmt from '@pages/Teacher/StudentMgmt';
import StatusList from '@pages/Teacher/StudentMgmt/StatusList';
import StudentDetail from '@pages/Teacher/StudentMgmt/StatusList/Detail';
import StudentEdit from '@pages/Teacher/StudentMgmt/StatusList/Edit';
import StudentTempList from '@pages/Teacher/StudentMgmt/TempStuList';
import StudentNcsManage from '@pages/Teacher/StudentMgmt/Ncs';
// ]

// 2. 교사관리(TeacherMgmt) [
import TeacherMgmt from '@pages/Teacher/TeacherMgmt';
import TeacherDetail from '@pages/Teacher/TeacherMgmt/Detail';
import TeacherRegister from '@pages/Teacher/TeacherMgmt/Register';
import TeacherEdit from '@pages/Teacher/TeacherMgmt/Edit';
// ]

// 3. 프로그램관리(ProgramMgmt) [
import ProgramMgmt from '@pages/Teacher/ProgramMgmt';
// ]

// 4. 바우처 일괄 전환(VoucherBatch)
import VoucherBatch from '@pages/Teacher/VoucherBatch';
// ]

// 5. 바우처 조회(VoucherLookup)
import VoucherLookup from '@pages/Teacher/VoucherLookup';
// ]

// 6. 매칭정보 (MatchingInfo)
import MatchingCompList from '@pages/Teacher/MatchingInfo/MatchingComp/Index';
import MatchingCompReport from '@pages/Teacher/MatchingInfo/MatchingComp/Report';
// ]

// *****************************************************
// *****************************************************
// *****************************************************

// ****** ****** ****** 교육청 관리자 ****** ****** ******
// 학생 관리
import AdmEduStudentStatus from '@pages/AdmEdu/Student/Status';
import AdmEduStudentDetail from '@pages/AdmEdu/Student/Status/Detail';
import AdmEduStudentFixedStatus from '@pages/AdmEdu/Student/FixedStatus';
import AdmEduProgramStatus from '@pages/AdmEdu/Student/Program';

// 부장교사 관리
import AdmEduTeacher from '@pages/AdmEdu/Teacher';
import AdmEduTeacherRegister from '@pages/AdmEdu/Teacher/Register';
import AdmEduTeacherDetail from '@pages/AdmEdu/Teacher/Detail';

// 바우처 관리
import AdmEduVoucherLookup from '@pages/AdmEdu/Voucher/Lookup';
import AdmEduVoucherAllotment from '@pages/AdmEdu/Voucher/Lookup/Allotment';
import AdmEduVoucherStatus from '@pages/AdmEdu/Voucher/Status';
import AdmEduVoucherStatusDetail from '@pages/AdmEdu/Voucher/Status/Detail';
import AdmEduVoucherScoreStat from '@pages/AdmEdu/Voucher/ScoreStat';
import AdmEduVoucherScoreMgmt from '@pages/AdmEdu/Voucher/ScoreMgmt';

// 게시물 관리
import AdmEduPostList from '@pages/AdmEdu/Post/List';
import AdmEduPostDetail from '@pages/AdmEdu/Post/Detail';
import AdmEduPostWrite from '@pages/AdmEdu/Post/Write';

// 만족도 조사 관리
import AdmEduSatisfactionSurvey from '@pages/AdmEdu/SatisfactionSurvey';

// 시스템 관리
import AdmEduSystemIp from '@pages/AdmEdu/System/Ip';
import AdmEduSystemSchedule from '@pages/AdmEdu/System/Schedule';
// import AdmEduSystemComp from '@pages/AdmEdu/System/Comp';
import AdmEduSystemCompList from '@pages/AdmEdu/System/MatchingComp/Index';
import AdmEduSystemCompWrite from '@pages/AdmEdu/System/MatchingComp/Write';
import AdmEduSystemCompReport from '@pages/AdmEdu/System/MatchingComp/Report';

// ****** ****** ****** 시스템 관리자 ****** ****** ******
import AdmSystemCodeMain from '@pages/AdmSystem/Code/Main';
import AdmSystemCodeDetail from '@pages/AdmSystem/Code/Detail';
import AdmSystemOrder from '@pages/AdmSystem/Order';
import AdmSystemMenu from '@pages/AdmSystem/Menu';

// 에러
import Errors from '@pages/Errors';

// 레이아웃x
import Layout from './Layout';
import AuthLayout from './AuthLayout';
import TermLayout from './TermLayout';

function Router({ isAuthorized }) {
  // const [state, dispatch] = useReducer(reducer, second, third)
  return (
    <Switch>
      {/* ****** ****** ****** 학생 ****** ****** ****** */}
      {/* home */}
      <Layout exact path="/" component={SignIn} />
      <TermLayout exact path="/privacy" component={Privacy} />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/home"
        component={Home}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/mypage"
        component={Mypage}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/mypage/:type"
        component={MypageEdit}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/service/notice"
        component={Notice}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/service/notice/detail/:seq"
        component={NoticeDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/service/archive"
        component={Archive}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/service/archive/detail/:seq"
        component={ArchiveDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/service/use-info"
        component={UseInfo}
      />

      {/* 진로설계 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/careerDesign"
        component={CareerDesignReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/careerDesign/comment/:seq"
        component={CareerDesignComment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/careerDesign/write/:seq"
        component={CareerDesignWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/careerDesign/write"
        component={CareerDesignWrite}
      />

      {/* 직무기초 포트폴리오 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/indivAbility/portfolio"
        component={PortfolioReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/portfolio/write"
        component={PortfolioWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/indivAbility/portfolio/comment/:studentIdx"
        component={PortfolioComment}
      />

      {/* 산업체 채용면접 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/indivAbility/visitIndustry"
        component={VisitIndustry}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/indivAbility/visitIndustry/:studentIdx"
        component={VisitIndustry}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/indivAbility/report/visitIndustry/:seq"
        component={VisitIndustryReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/visitIndustry/comment/:studentIdx/:seq"
        component={VisitIndustryComment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/write/visitIndustry"
        component={VisitIndustryWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/write/visitIndustry/:seq"
        component={VisitIndustryWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/ncs"
        component={IndAbilityNcs}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/indivAbility/ncs/register"
        component={IndAbilityNcsRegister}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/indivAbility/ncs/:studentIdx"
        component={StudentNcsManage}
      />

      {/* 현장실무능력 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/practiceAbility/:pageType"
        component={PracticeAbility}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/practiceAbility-teacher/:pageType/:studentIdx"
        component={PracticeAbility}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/practiceAbility/report/:pageType/:seq"
        component={PracticeAbilityReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/practiceAbility/comment/:pageType/:studentIdx/:seq"
        component={PracticeAbilityComment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/practiceAbility/write/:pageType"
        component={PracticeAbilityWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/practiceAbility/write/:pageType/:seq"
        component={PracticeAbilityWrite}
      />

      {/* 전공직무능력1 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/majorJobCert"
        component={MajorJobCert}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/majorJobCert-teacher/:studentIdx"
        component={MajorJobCert}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/majorJobCert/register"
        component={MajorJobCertRegister}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/majorJobCert/report/:grade"
        component={MajorJobReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/majorJobCert-teacher/comment/:studentIdx"
        component={MajorJobComment}
      />
      {/* 전공직무능력2 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/majorJobEdu/:pageType"
        component={MajorJobEdu}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/majorJobEdu-teacher/:pageType/:studentIdx"
        component={MajorJobEdu}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/majorJobEdu/report/:pageType/:seq"
        component={MajorJobEduReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/majorJobEdu/comment/:pageType/:studentIdx/:seq"
        component={MajorJobEduComment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/majorJobEdu/write/:pageType"
        component={MajorJobEduWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/majorJobEdu/write/:pageType/:seq"
        component={MajorJobEduWrite}
      />

      {/* 인성 자존감 활동 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/basicJob/tenacityAction"
        component={TenacityAction}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/basicJob/tenacityAction-teacher/:studentIdx"
        component={TenacityAction}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/basicJob/tenacityAction/report/:grade"
        component={TenacityActionReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/basicJob/tenacityAction-teacher/comment/:studentIdx"
        component={TenacityActionComment}
      />
      {/* 직업기초능력평가 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/employAbility/jobAssess"
        component={JobAssess}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/basicJob-teacher/jobAssess/:seq"
        component={JobAssess}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/employAbility/jobAssess/report/:seq"
        component={JobAssessReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/employAbility/jobAssess/report"
        component={JobAssessReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/employAbility/jobAssess/write/:seq"
        component={JobAssessWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/employAbility/jobAssess/write"
        component={JobAssessWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/basicJob/jobAssess/comment/:studentIdx/:seq"
        component={JobAssessComment}
      />
      {/* 취업역량강화 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/employAbility/:pageType"
        component={EmployAbility}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/employAbility-teacher/:pageType/:studentIdx"
        component={EmployAbility}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/employAbility/report/:pageType/:seq"
        component={EmployAbilityReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/employAbility/comment/:pageType/:studentIdx/:seq"
        component={EmployAbilityComment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/employAbility/write/:pageType"
        component={EmployAbilityWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/employAbility/write/:pageType/:seq"
        component={EmployAbilityWrite}
      />
      {/* 자율교육프로그램 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:type/selfEduProgram/report/:seq"
        component={SelfEduProgramReport}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/selfEduProgram/:grade"
        component={SelfEduProgram}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/selfEduProgram-teacher/:grade/:studentIdx"
        component={SelfEduProgram}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/selfEduProgram/write/:grade"
        component={SelfEduProgramWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/:loginType/studentMgmt/selfEduProgram/comment/:seq/:studentIdx"
        component={SelfEduProgramComment}
      />
      {/* 매칭정보 */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/student/matchingInfo/summaryReport"
        component={MatchingInfoReport}
      />
      {/* ****** ****** ****** 선생님 ****** ****** ****** */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/mypage"
        component={TeacherMypage}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/mypage/:type"
        component={TeacherMypageEdit}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/studentMgmt"
        component={StudentMgmt}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/statusList"
        component={StatusList}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/statusList/detail/:seq"
        component={StudentDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/statusList/edit/:seq"
        component={StudentEdit}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/studentMgmt/studentTemp"
        component={StudentTempList}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/teacherMgmt"
        component={TeacherMgmt}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/teacherMgmt/detail/:seq"
        component={TeacherDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/teacherMgmt/register"
        component={TeacherRegister}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/teacherMgmt/edit/:seq"
        component={TeacherEdit}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/programMgmt"
        component={ProgramMgmt}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/voucherBatch"
        component={VoucherBatch}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/voucherLookup"
        component={VoucherLookup}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/matchingInfo/comp"
        component={MatchingCompList}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/teacher/matchingInfo/comp/report/:seq"
        component={MatchingCompReport}
      />

      {/* ****** ****** ****** 교육청 관리자 ****** ****** ****** */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/student/status"
        component={AdmEduStudentStatus}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/student/detail/:seq"
        component={AdmEduStudentDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/student/fixedStatus"
        component={AdmEduStudentFixedStatus}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/student/program"
        component={AdmEduProgramStatus}
      />

      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/teacher"
        component={AdmEduTeacher}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/teacher/register"
        component={AdmEduTeacherRegister}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/teacher/detail/:seq"
        component={AdmEduTeacherDetail}
      />

      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/lookup"
        component={AdmEduVoucherLookup}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/lookup/allotment"
        component={AdmEduVoucherAllotment}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/status"
        component={AdmEduVoucherStatus}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/status/detail"
        component={AdmEduVoucherStatusDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/scoreStat"
        component={AdmEduVoucherScoreStat}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/voucher/scoreMgmt"
        component={AdmEduVoucherScoreMgmt}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/post/:boardId/list"
        component={AdmEduPostList}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/post/:boardId/detail/:seq"
        component={AdmEduPostDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/post/:boardId/write"
        component={AdmEduPostWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/satisfactionSurvey"
        component={AdmEduSatisfactionSurvey}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/ip"
        component={AdmEduSystemIp}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/schedule"
        component={AdmEduSystemSchedule}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/comp"
        component={AdmEduSystemCompList}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/comp/write"
        component={AdmEduSystemCompWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/comp/write/:seq"
        component={AdmEduSystemCompWrite}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admEdu/system/comp/report/:seq"
        component={AdmEduSystemCompReport}
      />

      {/*  ****** ****** ****** 시스템 관리자 ****** ****** ****** */}
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admSystem/code/main"
        component={AdmSystemCodeMain}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admSystem/code/detail/:maCd"
        component={AdmSystemCodeDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admSystem/code/detail"
        component={AdmSystemCodeDetail}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admSystem/order"
        component={AdmSystemOrder}
      />
      <AuthLayout
        isAuthorized={isAuthorized}
        exact
        path="/admSystem/menu"
        component={AdmSystemMenu}
      />

      {/* 에러 */}
      {/* <Layout isAuthorized={isAuthorized} component={Errors} /> */}
      <AuthLayout isAuthorized={isAuthorized} path="*" component={Errors} />
    </Switch>
  );
}

export default Router;
