import React from 'react';

export default React.memo(function SignIn(props) {
  //* return
  return (
    <main id="sign-in">
      <div className="py-3 px-5 bg-white">
        <p>
          『부산광역시교육청』은 개인정보 보호법 제30조에 따라 정보주체의
          개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
          있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립·공개합니다.
        </p>
        <br />
        {/* 1조 */}
        <h2>
          제1조 (개인정보의 처리목적, 개인정보의 처리 및 보유기간, 처리하는
          개인정보의 항목)
        </h2>
        <br />
        <p>
          부산광역시교육청이 개인정보 보호법 제32조에 따라 등록·공개하는
          개인정보파일은 개인정보보호위원회 개인정보 포털(www.privacy.go.kr) ⇨
          개인서비스 ⇨ 정보주체 권리행사 ⇨ 개인정보 열람등요구 ⇨ 개인정보파일
          검색 메뉴를 통해 공개하고 있습니다.
        </p>
        <br />
        <p>▶ 기관명에 '부산광역시교육청'을 입력하면 조회가 가능합니다.</p>
        <br />
        {/* 2조 */}
        <h2>제2조 (개인정보의 제3자 제공)</h2>
        <br />
        <p>
          ➀ 부산광역시교육청은 원칙적으로 정보주체의 개인정보를 수집·이용
          목적으로 명시한 범위내에서 처리하며, 다음의 경우를 제외하고는
          정보주체의 별도의 동의 없이는 본래의 목적 범위를 초과하여 처리하거나
          제3자에게 제공하지 않습니다.
        </p>
        <br />
        <p>&nbsp;&nbsp;1. 다른 법률에 특별한 규정이 있는 경우</p>
        <br />
        <p>
          &nbsp;&nbsp;2. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는
          상태에 있거나 주소불명 등 으로 사전 동의를 받을 수 없는 경우로서
          명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여
          필요하다고 인정되는 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp; 3. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게
          제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는
          경우로서 보호위원회의 심의·의결을 거친 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp; 4. 조약, 그 밖의 국제협정의 이행을 위하여 외국정보 또는
          국제기구에 제공하기 위하여 필요한 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp;5. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
        </p>
        <br />
        <p>&nbsp;&nbsp;6. 법원의 재판업무 수행을 위하여 필요한 경우</p>
        <br />
        <p>
          &nbsp;&nbsp;7. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우
        </p>
        <br />
        <p>
          ➁ 제1항에 대한 사항은 "개인정보 제3자 제공 게시판" 에 게재하여
          정보주체가 확인할 수 있도록 안내하고 있습니다.
        </p>
        <p>
          - 수집 항목(필수): 아이디, 비밀번호, 소속학교, 학교코드, 성명, 이메일
        </p>
        <br />
        {/* 2-2조 */}
        <h2>제2조의2 (개인정보의 추가적인 이용‧제공 기준)</h2>
        <br />
        <p>
          부산광역시교육청은 개인정보 보호법 제15조제3항 또는 제17조제4항에 따라
          정보주체의 동의 없이 개인정보를 이용 또는 제공하려는 경우는 다음의
          경우를 고려하겠습니다.
        </p>
        <br />
        <p>&nbsp;&nbsp;1. 당초 수집 목적과 관련성이 있는지 여부</p>
        <br />
        <p>
          &nbsp;&nbsp; 2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때
          개인정보의 추가적인 이용 또는 제공에 대한 예측가능성이 있는지 여부
        </p>
        <br />
        <p>&nbsp;&nbsp;3. 정보주체의 이익을 부당하게 침해하는지 여부</p>
        <br />
        <p>
          &nbsp;&nbsp;4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를
          하였는지 여부
        </p>
        <br />
        {/* 2-3조 */}
        <h2>제2조의3 (가명정보 처리에 관한 사항)</h2>
        <br />
        <p>
          부산광역시교육청은 가명정보 처리 시 처리 목적, 처리하는 가명정보의
          항목 등에 대한 사항을 확인할 수 있도록 "가명정보 처리 게시판"에
          안내하고 있습니다.
        </p>
        <br />
        {/* 3조 */}
        <h2>제3조 (개인정보 처리 위탁)</h2>
        <br />
        <p>
          개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 위탁받아 처리하는
          자에 대한 사항을 "개인정보처리 위탁 게시판" 에 게재하여 정보주체가
          확인할 수 있도록 안내하고 있습니다.
        </p>
        <br />
        {/* 4조 */}
        <h2>제4조 (정보주체와 법정대리인의 권리․의무 및 그 행사 방법)</h2>
        <br />
        <p>
          정보주체는 다음과 같은 권리를 행사할 수 있으며, 만14세 미만 아동의
          법정대리인은 그 아동의 개인정보에 대한 열람, 정정, 삭제, 처리정지를
          요구할 수 있습니다.
        </p>
        <br />
        <p>➀ 개인정보 열람 요구</p>
        <br />
        <p>
          &nbsp;부산광역시교육청에서 보유하고 있는 개인정보파일은 「개인정보
          보호법」 제35조(개인정보의 열람)에 따라 자신의 개인정보에 대한 열람을
          요구할 수 있습니다. 다만, 개인정보 열람 요구는 법 제35조제4항에 의하여
          다음과 같이 제한될 수 있습니다.
        </p>
        <br />
        <p>&nbsp;&nbsp;1. 법률에 따라 열람이 금지되거나 제한되는 경우 </p>
        <br />
        <p>
          &nbsp;&nbsp;2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의
          재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp;3. 공공기관이 다음 각 목의 어느 하나에 해당하는 업무를
          수행할 때 중대한 지장을 초래하는 경우
        </p>
        <br />
        <p>&nbsp;&nbsp;&nbsp;가. 조세의 부과·징수 또는 환급에 관한 업무</p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;나.「초·중등교육법」 및 「고등교육법」에 따른 각급
          학교, 「평생교육법」에 따른 평생교육시설, 그 밖의 다른 법률에 따라
          설치된 고등교육기관에서의 성적 평가 또는 입학자 선발에 관한 업무
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;다. 학력·기능 및 채용에 관한 시험, 자격 심사에 관한
          업무
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;라. 보상금·급부금 산정 등에 대하여 진행 중인 평가
          또는 판단에 관한 업무
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;마. 다른 법률에 따라 진행 중인 감사 및 조사에 관한
          업무
        </p>
        <br />
        <p>➁ 정정·삭제요구 요구</p>
        <br />
        <p>
          &nbsp;부산광역시교육청에서 보유하고 있는 개인정보파일은 「개인정보
          보호법」 제36조(개인정보의 정정·삭제)에 따라 정정·삭제를 요구할 수
          있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어
          있는 경우에는 그 삭제를 요구할 수 없습니다.
        </p>
        <br />
        <p>➂ 처리정지 요구</p>
        <br />
        <p>
          &nbsp;부산광역시교육청에서 보유하고 있는 개인정보파일은 「개인정보
          보호법」 제37조(개인정보의 처리정지 등)에 따라 처리정지를 요구할 수
          있습니다. 다만, 개인정보 처리정지 요구 시 법 제37조제2항에 의하여
          다음과 같이 처리정지 요구가 거절될 수 있습니다.
        </p>
        <br />
        <p>
          &nbsp;&nbsp;1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기
          위하여 불가피한 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp;2. 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의
          재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp;3. 공공기관이 개인정보를 처리하지 아니하면 다른 법률에서
          정하는 소관 업무를 수행할 수 없는 경우
        </p>
        <br />
        <p>
          &nbsp;&nbsp;4. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를
          제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의
          해지 의사를 명확하게 밝히지 아니한 경우
        </p>
        <br />
        <p>
          ➃ 개인정보 열람, 정정·삭제, 처리정지 요구는 교육부 개인정보 보호지침
          [별지 제1호 서식] 개인정보(열람, 정정·삭제, 처리정지) 요구서에 따라
          작성 후 개인정보 보호위원회 개인정보 포털(개인서비스 ⇨ 개인정보
          열람등요구 ⇨ 열람등요구 ), 서면, 전자우편, 모사전송(FAX) 등을 통하여
          하실 수 있으며, 부산광역시교육청은 이에 대해 지체 없이 조치하겠습니다.
        </p>
        <br />
        <p>
          ➄ 제4항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 교육부 개인정보 보호지침
          [별지 제2호 서식] 위임장을 제출하셔야 합니다.
        </p>
        <br />
        <p>
          ➅ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
          경우에는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
          제공하지 않습니다.
        </p>
        <br />
        <p>
          ➆ 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구
          시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
        </p>
        <br />
        <p>
          ⑧ 수집 출처 등 고지 정보주체 이외로부터 수집한 개인정보를 처리할 시
          「개인정보 보호법」제20조에 따라 개인정보의 수집 출처, 개인정보의 수집
          목적 및 법 제37조에 따른 개인정보 처리의 정지를 요구할 권리가 있다는
          사실을 고지하도록 하겠습니다.
        </p>
        <br />

        {/* 5조 */}
        <h2>제5조 (개인정보 자동 수집 및 거부)</h2>
        <br />
        <p>
          ➀ 부산광역시교육청 홈페이지는 이용자 편의성을 제공하기 위해 이용정보를
          저장하고 불러오는 '쿠키(cookie)'를 사용합니다.
        </p>
        <br />
        <p>
          ➁ 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
          브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
          하드디스크에 저장되기도 합니다.
        </p>
        <br />
        <p>&nbsp;1. 쿠키의 사용 목적: 팝업차단 기능 제공</p>
        <br />
        <p>
          &nbsp;2. 쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구 `{'>'}`
          인터넷 옵션 `{'>'}` 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을
          거부할 수 있습니다.
        </p>
        <br />
        <p>
          &nbsp;3. 쿠키 저장을 거부할 경우 홈페이지 팝업차단 기능이 제공되지
          않을 수 있습니다.
        </p>
        <br />

        {/* 6조 */}
        <h2>제6조 (개인정보의 파기절차 및 파기방법)</h2>
        <br />
        <p>
          ➀ 부산광역시교육청은 원칙적으로 개인정보의 처리목적이 달성된
          개인정보는 지체 없이 파기합니다. 다른 법령에 따라 개인정보를 계속
          보존하여야 하는 경우에는 개인정보 처리방침을 통하여 해당 개인정보
          항목과 보존근거를 공개하겠습니다. 파기의 절차, 기한 및 방법은 다음과
          같습니다.
        </p>
        <br />
        <p>&nbsp;1. 파기절차</p>
        <br />
        <p>
          &nbsp;&nbsp;불필요한 개인정보 및 개인정보파일은 개인정보 보호책임자의
          책임 하에 부산광역시교육청의 내부 관리계획 절차에 따라 다음과 같이
          처리하고 있습니다.
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;가. 개인정보의 파기: 보유기간이 경과한 개인정보는
          종료일로부터 지체 없이 파기합니다.
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;나. 개인정보파일의 파기: 개인정보파일의 처리 목적
          달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게
          되었을 때에는 개인정보의 처리가 불필요한 것으로 인정 되는 날로부터
          지체 없이 그 개인정보파일을 파기합니다.
        </p>
        <br />
        <p>&nbsp; 2. 파기방법</p>
        <br />
        <p>
          &nbsp;&nbsp;가. 전자적 형태의 정보는 기록을 재생할 수 없는 기술적
          방법을 사용합니다.
        </p>
        <br />
        <p>
          &nbsp;&nbsp;나. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
          통하여 파기합니다.
        </p>
        <br />

        {/* 7조 */}
        <h2>제7조 (개인정보의 안전성 확보 조치)</h2>
        <p>
          부산광역시교육청은 개인정보의 안전성 확보를 위해 다음과 같이 관리적,
          기술적, 물리적 조치를 취하고 있습니다.
        </p>
        <br />
        <p>&nbsp;1. 내부관리계획의 수립 및 시행</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보의 안전한 처리를 위해 관련 법령 및 관리지침을
          준수하여 내부관리계획을 수립 및 시행하고 있습니다.
        </p>
        <br />
        <p>&nbsp;2. 개인정보 취급직원의 최소화 및 교육</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여
          지정‧관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을
          실시하고 있습니다.
        </p>
        <br />
        <p>&nbsp;3. 개인정보에 대한 접근 제한</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의
          부여‧변경‧말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를
          하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을
          통제하고 있습니다.
        </p>
        <br />
        <p>&nbsp;4. 접속기록의 보관</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보처리시스템에 접속한 기록을 최소 12개월 이상
          보관‧관리하고 있습니다.
        </p>
        <br />
        <p>&nbsp;5. 개인정보의 암호화</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고
          있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는
          등의 별도 보안기능을 사용하고 있습니다.
        </p>
        <br />
        <p>&nbsp;6. 보안프로그램 설치 및 주기적 점검‧갱신</p>
        <br />
        <p>
          &nbsp;&nbsp;해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
          막기 위하여 보안프로그램을 설치하고 주기적으로 갱신‧점검하고 있습니다.
        </p>
        <br />
        <p>&nbsp;7. 비인가자에 대한 출입 통제</p>
        <br />
        <p>
          &nbsp;&nbsp;개인정보를 보관하고 있는 개인정보처리시스템의 물리적 보관
          장소를 별도로 두고 이에 대해 출입통제 절차를 수립‧운영하고 있습니다.
        </p>
        <br />

        {/* 8조 */}
        <h2>제8조 (개인정보 보호책임자)</h2>
        <br />
        <p>
          부산광역시교육청은 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
          아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <br />
        <p className="mt-3">
          <table className="table w-50">
            <colgroup>
              <col width={120} />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="3">개인정보 보호책임자</th>
                <th colSpan="3">개인정보 보호담당자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>책임자</td>
                <td colSpan="2">디지털미래교육과장 김현구</td>
                <td>담당자</td>
                <td colSpan="2">디지털미래교육과 노진호</td>
              </tr>
              <tr>
                <td>연락처</td>
                <td colSpan="2">051-860-0350</td>
                <td>연락처</td>
                <td colSpan="2">051-860-0368</td>
              </tr>
            </tbody>
          </table>
        </p>
        <br />

        {/* 9조 */}
        <h2>제9조 (개인정보 열람 청구)</h2>
        <br />
        <p>
          ➀ 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를
          개인정보파일 보유부서에 할 수 있습니다.
        </p>
        <br />
        <p>
          ➁ 이외에 개인정보보호위원회의 '개인정보 포털'(www.privacy.go.kr)를
          통해서도 개인정보 열람청구를 하실 수 있습니다.
        </p>
        <br />

        {/* 10조 */}
        <h2>제10조 (권익침해 구제 방법)</h2>
        <br />
        <p>
          ➀ 정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
          분쟁해결이나 상담 등을 신청할 수 있습니다.
        </p>
        <br />
        <p>
          ➁ 부산광역시교육청의 자체적인 개인정보 불만처리, 피해구제 결과에
          만족하지 못하시거나 보다 자세한 도움이 필요하시면 아래의 기관에
          문의하시기 바랍니다.
        </p>
        <br />
        <p>
          &nbsp;▷ 개인정보분쟁조정위원회 : (국번없이) 1833-6972
          (www.kopico.go.kr)
        </p>
        <br />
        <p>
          &nbsp;▷ 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
        </p>
        <br />
        <p>&nbsp;▷ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</p>
        <br />
        <p>&nbsp;▷ 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</p>
        <br />
        <p>
          ➂ ｢개인정보 보호법｣ 제35조(개인정보의 열람), 제36조(개인정보의
          정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
          공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를
          받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
        </p>
        <br />
        <p>&nbsp;▷ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</p>
        <br />

        {/* 11조 */}
        <h2>제11조 (개인정보 처리방침 변경)</h2>
        <br />
        <p>
          이 개인정보 처리방침은 2023. 7. 25.부터 적용됩니다. 이전의 개인정보
          처리방침은 아래에서 확인하실 수 있습니다.
        </p>
        <br />
      </div>
    </main>
  );
});
